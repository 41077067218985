import React, { useState, useEffect } from "react";
import { Divider, Checkbox, Modal, Form, Button } from "semantic-ui-react";
import Loadable from "react-loadable";
//import Loading from "../../utils/Loading"

import Tips from "./Tips";

const LoadableEdit = Loadable({
  loader: () => import("../../../common/components/CKEdit"),
  loading() {
    return <div>Loading...</div>;
  },

  render(loaded, props) {
    let Component = loaded.default;
    return (
      <Component
        key={props.id}
        id={props.id}
        text={props.text}
        handleChangeText={props.handleChangeText}
        handleAccordionSelect={props.handleAccordionSelect}
        type={props.type}
      />
    );
  },
});

export const TextEditor = ({
  text,
  handleChangeText,
  handleAccordionSelect,
  type,
  id,
}) => {
  return (
    <div key={id+'div'}>
      <LoadableEdit
        key={id}
        id={id}
        text={text}
        handleChangeText={handleChangeText}
        handleAccordionSelect={handleAccordionSelect}
        type={type}
      />
      <Tips />
      <Divider />
      <h5>Switch to accordion</h5>
      <Checkbox
        toggle
        onChange={handleAccordionSelect}
        checked={type === "accordion"}
      />
      {type === "accordion" ? (
        <p style={{ color: "green" }}>Accordion</p>
      ) : (
        <p>Text View</p>
      )}
    </div>
  );
};
export default TextEditor;

// const TextEditor = ({text, handleChangeText, handleAccordionSelect, type}) => {

//   const [ckEditor, update_ckEditor] = useState(<div>hello</div>)

//   useEffect(() => {
//     console.log('updateing ckeditor')
//     update_ckEditor(
//   <CKEditor
//     editor={ ClassicEditor }
//     data={text}
//     onInit={ editor => {
//         // You can store the "editor" and use when it is needed.
//     } }
//     onChange={ ( event, editor ) => {
//         const data = editor.getData();
//         console.log( { event, editor, data } );
//         handleChangeText(data)
//     } }
//     onBlur={ ( event, editor ) => {
//         console.log( 'Blur.', editor );
//     } }
//     onFocus={ ( event, editor ) => {
//         console.log( 'Focus.', editor );
//     } }
// />
//     )
//   }, []);

//   return (
//     <div>
//     {ckEditor}
//     <Tips />
//     <Divider />
//     <h5>Switch to accordion</h5>
//     <Checkbox toggle
//       onChange={handleAccordionSelect}
//       checked={type === 'accordion'}
//     />
//     {type === 'accordion' ? (<p style={{color: 'green'}}>Accordion</p>):(<p >Text View</p>)}
//   </div>
//   )
// };

// export default TextEditor;
