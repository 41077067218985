import React from "react";
import {
  Form,
  Button,
  Modal,
  Dropdown,
  Divider,
  Message,
  Radio,
} from "semantic-ui-react";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";

import {
  updateAlternativeLink,
  updateDestination,
  handleChange,
  updateColour,
  updateIcon,
  handleExtLinkChange,
  handleAccordionSelect,
  upload,
  sectionFormChecker,
  nullImage,
  handlePageLinkChange,
  handleChangeText,
} from "./components/SectionFunctions";

import TextEditor from "./components/TextEditor";
import ImageSection from "./components/ImageSection";
import LinkSection from "./components/LinkSection";
import ExternalLinkSection from "./components/ExternalLinkSection";
import CTA from "./components/CTA";
import InfoBox from "./components/InfoBox";

import { addSection } from "../../actions";

class SectionNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      type: "text",
      text: "",
      section_id: this.props.page_id,
      image_url: null,
      destination: "",
      colour: "#0F9E97",
      icon: "check",
      ext_link: null,
      image_hash: null,
      image_type: "external",
      image_style: null,
      original_image_key: null,
      modalOpen: false,
      user: props.user,
      error: false,
      error_message: "",
      page_link_type: "Normal",
      alternative_link: null,
      data: "none",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sectionFormChecker = sectionFormChecker.bind(this);
    this.baseState = this.state;
  }
  componentDidMount() {
    this.setState(this.baseState);
  }

  handleOpen = () =>
    this.setState({
      modalOpen: true,
      title: "",
      type: "text",
      text: "",
      section_id: this.props.page_id,
      image_url: null,
      destination: "",
      colour: "#0F9E97",
      icon: "check",
      ext_link: null,
      image_hash: null,
      original_image_key: null,
      error: false,
      error_message: "",
      page_link_type: "Normal",
      alternative_link: null,
      button_text: "",
      data: "none",
    });
  handleClose = () => this.setState({ modalOpen: false });

  handleChange(event) {
    console.log("we are here");
    const name = event.target.name;
    console.log({ [name]: event.target.value });
    this.setState({ [name]: event.target.value });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    await this.sectionFormChecker();
    if (this.state.error === true) {
      return;
    }
    if (this.state.error === false) {
      const section_count = _.filter(this.props.sections, {
        section_id: this.props.page_id,
      });
      const total_sections = _.sortedIndex(section_count);
      console.log(this.state.user["custom:trust"]);
      console.log("this.state.user");
      console.log(this.props.user["custom:trust"]);
      // return;
      const params = {
        user_id: this.props.page.user_id,
        section_id: this.state.section_id,
        title: this.state.title,
        type: this.state.type,
        text: this.state.text,
        index_number: total_sections,
        image_url: this.state.image_url,
        destination: this.state.destination,
        image_hash: this.state.image_hash,
        original_image_key: this.state.original_image_key,
        colour: this.state.colour,
        icon: this.state.icon,
        ext_link: this.state.ext_link,
        page_link_type: this.state.page_link_type,
        alternative_link: this.state.alternative_link,
        button_text: this.state.button_text,
        data: this.state.data,
      };
      console.log(params);

      await this.props.addSection(params);
      this.setState(this.baseState);
      this.setState({ modalOpen: false, title: "", type: "text" });
    }
  };

  add_image_to_section = (chosen_image) => {
    console.log("adding to section");
    console.log(chosen_image);
    this.setState({ image_hash: chosen_image.hash });
    this.setState({ image_url: chosen_image.image_url });
  };

  updateImageStyle = (data) => {
    console.log("updating style");
    this.setState({ image_style: data });
  };

  returnTextLinkSection = () => {
    switch (this.state.data) {
      case "none":
        return <></>;
        break;

      case "internal":
        return (
          <LinkSection
            alternative_link={this.state.alternative_link}
            handlePageLinkChange={handlePageLinkChange.bind(this)}
            updateAlternativeLink={updateAlternativeLink.bind(this)}
            page_link_type={this.state.page_link_type}
            section={this.state}
            pages={this.props.pages}
            updateDestination={updateDestination.bind(this)}
            updateColour={updateColour.bind(this)}
            updateIcon={updateIcon.bind(this)}
            handleChange={handleChange.bind(this)}
            button_text={this.state.button_text}
          />
        );
        break;
      case "external":
        return (
          <ExternalLinkSection
            section={this.state}
            destination={this.state.destination}
            handleExtLinkChange={handleExtLinkChange.bind(this)}
            ext_link={this.state.ext_link}
            handleChange={handleChange.bind(this)}
            updateColour={updateColour.bind(this)}
            updateIcon={updateIcon.bind(this)}
            upload={upload.bind(this)}
            uploadModalOpen={this.state.uploadModalOpen}
            imageUrl={this.state.image_url}
            nullImage={nullImage.bind(this)}
          />
        );
        break;
      default:
        break;
    }
  };

  renderSection() {
    const timestamp = moment().unix();
    switch (this.state.type) {
      case "accordion":
      case "text":
        return (
          <TextEditor
            text={this.state.text}
            handleChangeText={handleChangeText.bind(this)}
            handleAccordionSelect={handleAccordionSelect.bind(this)}
            type={this.state.type}
          />
        );
      case "image":
        return (
          <ImageSection
            image_style={this.state.image_style}
            add_image_to_section={this.add_image_to_section}
            uploadModalOpen={this.state.uploadModalOpen}
            update_image_style={this.updateImageStyle}
          />
        );
      case "link":
        return (
          <LinkSection
            alternative_link={this.state.alternative_link}
            handlePageLinkChange={handlePageLinkChange.bind(this)}
            updateAlternativeLink={updateAlternativeLink.bind(this)}
            page_link_type={this.state.page_link_type}
            section={this.state}
            pages={this.props.pages}
            updateDestination={updateDestination.bind(this)}
            updateColour={updateColour.bind(this)}
            updateIcon={updateIcon.bind(this)}
            handleChange={handleChange.bind(this)}
            button_text={this.state.button_text}
          />
        );
      case "ext_link":
        return (
          <ExternalLinkSection
            section={this.state}
            destination={this.state.destination}
            handleExtLinkChange={handleExtLinkChange.bind(this)}
            ext_link={this.state.ext_link}
            handleChange={handleChange.bind(this)}
            updateColour={updateColour.bind(this)}
            updateIcon={updateIcon.bind(this)}
          />
        );
      case "info":
        return (
          <InfoBox
            text={this.state.text}
            handleChangeText={handleChangeText.bind(this)}
            handleAccordionSelect={handleAccordionSelect.bind(this)}
            type={this.state.type}
          />
        );
      case "cta":
        return (
          <div>
            <CTA
              text={this.state.text}
              colour={this.state.colour}
              handleChange={handleChange.bind(this)}
              updateColour={updateColour.bind(this)}
            />
            <div>
              <Form>
                <Form.Field>
                  Selected value: <b>{this.state.data}</b>
                </Form.Field>
                <Form.Field>
                  <Radio
                    label="No Link"
                    name="radioGroup"
                    value="none"
                    checked={this.state.data === "none"}
                    onChange={() => this.setState({ data: "none" })}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label="Internal Link"
                    name="radioGroup"
                    value="internal"
                    checked={this.state.data === "internal"}
                    onChange={() => this.setState({ data: "internal" })}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label="External Link"
                    name="radioGroup"
                    value="external"
                    checked={this.state.data === "external"}
                    onChange={() => this.setState({ data: "external" })}
                  />
                </Form.Field>
              </Form>
              <Divider />

              {this.returnTextLinkSection()}
            </div>
          </div>
        );
      case "checkbox":
        return (
          <div>
            <CTA
              text={this.state.text}
              colour={this.state.colour}
              handleChange={handleChange.bind(this)}
              updateColour={updateColour.bind(this)}
            />
            <div>
              <Form>
                <Form.Field>
                  Selected value: <b>{this.state.data}</b>
                </Form.Field>
                <Form.Field>
                  <Radio
                    label="No Link"
                    name="radioGroup"
                    value="none"
                    checked={this.state.data === "none"}
                    onChange={() => this.setState({ data: "none" })}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label="Internal Link"
                    name="radioGroup"
                    value="internal"
                    checked={this.state.data === "internal"}
                    onChange={() => this.setState({ data: "internal" })}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label="External Link"
                    name="radioGroup"
                    value="external"
                    checked={this.state.data === "external"}
                    onChange={() => this.setState({ data: "external" })}
                  />
                </Form.Field>
              </Form>
              <Divider />

              {this.returnTextLinkSection()}
            </div>
          </div>
        );
      default:
    }
    return <div>hello</div>;
  }

  render() {
    const types = [
      { key: "text", text: "Text", value: "text" },
      { key: "image", text: "Image", value: "image" },
      { key: "link", text: "Link", value: "link" },
      { key: "ext_link", text: "External URL", value: "ext_link" },
      { key: "info", text: "Information Box", value: "info" },
      { key: "cta", text: "Call to action", value: "cta" },
      { key: "checkbox", text: "Checkbox", value: "checkbox" },
    ];
    return (
      <Modal
        trigger={
          <Button primary onClick={this.handleOpen}>
            New Section
          </Button>
        }
        open={this.state.modalOpen}
        size={"tiny"}
        onClose={this.handleClose}
      >
        <Modal.Header>Add New Section</Modal.Header>
        <Modal.Content>
          {this.state.error === true ? (
            <Message error header={this.state.error_message} />
          ) : (
            <div />
          )}
          <Form>
            <Form.Field>
              <label>
                Name:
                <input
                  name="title"
                  type="text"
                  value={this.state.title}
                  onChange={this.handleChange}
                  autoComplete="off"
                />
              </label>
              {/* <div>{JSON.stringify(this.props.page.user_id)}</div> */}
              <label>Section Type: </label>
              <Dropdown
                placeholder="Select Section Type"
                fluid
                selection
                defaultValue={this.state.type}
                options={types}
                onChange={(e, { value }) => this.setState({ type: value })}
              />
              <Divider />
              {this.renderSection()}
              <Divider />
              <Button primary onClick={this.handleSubmit} content="Save" />
              <Button secondary onClick={this.handleClose} content="Close" />
            </Form.Field>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return { sections: state.sections, pages: state.pages, user: state.user };
};

export default connect(mapStateToProps, { addSection })(SectionNew);
