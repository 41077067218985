import React from 'react'
import { Button, Modal, Divider, Label, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux';



class Tips extends React.Component {
  constructor(props) {
  super(props);
  this.state = {modalOpen: false};
  }

  handleOpen = () => this.setState({ modalOpen: true })
  handleClose = () => this.setState({ modalOpen: false })

  render() {
    return (
      <Modal
        size='tiny'
        trigger={<Label
          style={{marginTop:10}}
        onClick={this.handleOpen}
        >
          <Icon name='question' /> Tips
        </Label>}
        open={this.state.modalOpen}
        onClose={this.handleClose}
        >
        <Modal.Header>Tips</Modal.Header>
        <Modal.Content>
        <h3>Adding links</h3>
        <p>You can link to web, phone, sms and email. These will open natively in the app.
        Simply add the text you want to have displayed on the app then hightlight the text and select the link button at the top of the text editor
        Follow the guide below to see the different options.
        </p>
        <ul>
          <li>Weblinks enter a vaid URL.<small>   (example 'https://www.example.com')</small></li>
          <li>Phone links include <strong><code>tel:</code></strong> <small>   (example 'tel:07711223344')</small></li>
          <li>SMS links include <strong><code>sms:</code></strong><small>   (example 'sms:07711223344')</small></li>
          <li>Email links include <strong><code>mailto:</code></strong><small>  (example 'mailto:this@example.com')</small></li>
        </ul>
              <Divider/>
              <Button
                secondary
                onClick={this.handleClose}
                content="Close"
              />
        </Modal.Content>
      </Modal>
    )
  }
}
const mapStateToProps = state => {
  return { tiles: state.tiles};
};

export default connect(mapStateToProps, {  })(Tips);
