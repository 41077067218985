import React from "react";
import {
  Form,
  Button,
  TextArea,
  Dropdown,
  Divider,
  Message,
} from "semantic-ui-react";
import colours from "../../../data/colours.json";

const CTA = ({ text, colour, handleChange, updateColour }) => {
  return (
    <div>
      <label>
        Text
        {/* {props.section.text} */}
        <TextArea
          name="text"
          placeholder="Input your text for the call to action box here"
          value={text}
          style={{ minHeight: 100 }}
          onChange={(e, { value }) => handleChange(e)}
        />
      </label>
      <label>
        Colour
        <Dropdown
          placeholder="Select Colour"
          fluid
          selection
          search
          name="text"
          defaultValue={colour}
          options={colours}
          onChange={(e, { value }) => updateColour(value)}
        />
        <Divider />
      </label>
    </div>
  );
};

export default CTA;
