import React from "react";
import { useParams } from "gatsby";
import Sections from "./Sections";

function Page({ location, navigate, search }) {
  return (
    <div>
      <Sections id={search.id} />
    </div>
  );
}

export default Page;
