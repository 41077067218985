import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchPages,
  update_Page,
  fetchSections,
  update_Section,
  removeSection,
  fetchTemplates,
  addPage,
  addSection,
  fetchPage,
} from "../../actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Grid,
  Loader,
  Dimmer,
  Button,
  Divider,
  Segment,
} from "semantic-ui-react";
import _ from "lodash";

import NoAccess from "../NoAccess";
import PageCopy from "./PageCopy";
import PageCopyTemplate from "./PageCopyTemplate";
import SectionsList from "../sections/SectionsList";
import MobileTiles from "../mobile/Tiles/MobileTiles";

const Sections = (props) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user["custom:trust"]);
  const pages = useSelector((state) => state.pages);
  const sections = useSelector((state) => state.sections);
  const templates = useSelector((state) => state.templates);
  const [page, update_page_info] = useState([]);
  const [loading, update_loading] = useState(true);
  const [activeIndex, update_activeIndex] = useState(-1);
  const [updated_sections, update_updated_sections] = useState([]);
  const [loading_sections, update_loading_sections] = useState(false);

  useEffect(() => {
    dispachData();
    getData();
  }, []);

  function dispachData() {
    dispatch(fetchPages(user));
    dispatch(fetchSections(user));
    dispatch(fetchTemplates());
  }

  async function getData() {
    try {
      console.log("getting details");

      const response = await fetchPage(props.id);
      console.log("response");
      console.log(response);

      var pageDetails = response;
      console.log("pageDetails");
      console.log(pageDetails);
      // if (pageDetails === undefined) {
      //   console.log("templates");
      //   console.log(templates);
      //   pageDetails = _.find(templates, { page_id: props.id });
      //   console.log(pageDetails);
      // }

      if (pageDetails !== undefined) {
        // dispachData();

        filterSection();
        update_page_info(pageDetails);
        update_loading(false);
        update_loading_sections(false);
      }
      if (pageDetails === undefined) {
        // dispachData();
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    filterSection();
    // update_loading(false);
  }, [sections]);

  function filterSection() {
    try {
      const updatedSections = _.filter(sections, {
        section_id: props.id,
      });
      const ordered_section = _.orderBy(updatedSections, ["index_number"]);
      update_updated_sections(ordered_section);
    } catch (error) {
      console.log(error);
    }
  }

  function reindexSections(sections_to_order) {
    const sections_to_index = sections_to_order || updated_sections;
    sections_to_index.map((entry, index) => {
      if (entry.index_number !== index) {
        dispatch(
          update_Section(
            {
              Item: {
                id: entry.id,
                index_number: index,
              },
            },
            user
          )
        );
      }
    });
  }

  function updateActiveIndex(index) {
    if (index === activeIndex) {
      update_activeIndex(-1);
      return;
    }
    update_activeIndex(index);
  }

  function handleDeleteSection(section) {
    const id = section.id;
    dispatch(removeSection(id));
    var removed = _.remove(updated_sections, (item) => item.id !== id);
    console.log("new_list");
    console.log(removed);
    update_updated_sections(removed);
    reindexSections(removed);
  }

  async function moveSection(section, movement) {
    // update_loading(true);
    const id = section.id;
    var actual_section_index = null;
    const length = updated_sections.length;

    updated_sections.map((entry, index) => {
      if (entry.id === id) actual_section_index = index;
      console.log(entry);
    });

    console.log(updated_sections.length);
    console.log(actual_section_index);
    const number_of_sections = updated_sections.length;

    console.log(actual_section_index);
    const id_of_section = updated_sections[actual_section_index].id;
    if (movement === "up" && section.index_number !== 0) {
      const id_of_section_above = updated_sections[actual_section_index - 1].id;
      await dispatch(
        update_Section(
          {
            Item: {
              id: id_of_section,
              index_number: actual_section_index - 1,
            },
          },
          user
        )
      );
      await dispatch(
        update_Section(
          {
            Item: {
              id: id_of_section_above,
              index_number: actual_section_index,
            },
          },
          user
        )
      );
      updateActiveIndex(activeIndex - 1);
    }

    if (movement === "down" && actual_section_index !== length - 1) {
      const id_of_section_below = updated_sections[actual_section_index + 1].id;
      await dispatch(
        update_Section(
          {
            Item: {
              id: id_of_section,
              index_number: actual_section_index + 1,
            },
          },
          user
        )
      );
      await dispatch(
        update_Section(
          {
            Item: {
              id: id_of_section_below,
              index_number: actual_section_index,
            },
          },
          user
        )
      );
      updateActiveIndex(activeIndex + 1);
    }
  }

  function switchPageToCommon() {
    console.log("switch");
    var error = false;
    updated_sections.map((section) => {
      //Check there are no links
      if (section.type === "link") {
        error = true;
      }
    });
    switch (error) {
      case false:
        dispatch(
          update_Page({
            Item: {
              id: page.id,
              user_id: "common",
            },
          })
        );

        updated_sections.map((section) => {
          dispatch(
            update_Section({
              Item: {
                id: section.id,
                user_id: "common",
              },
            })
          );
        });

        fireToast("success");
        break;

      case true:
        fireToast("error");
        break;

      default:
        break;
    }
  }

  function fireToast(type) {
    switch (type) {
      case "success":
        toast.success("Submitted - Thank you!", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case "error":
        toast.error("Error - Please remove the link sections", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      default:
        break;
    }
  }

  function renderSectionList() {
    // if(this.state.page.restrict_to_admin === true && this.props.user['custom:level'] === 'basic'){
    //   return <NoAccess
    //     message={'Sorry, you dont have access rights to edit this page'}
    //     margin={1}
    //   />
    // }
    if (loading) {
      return (
        <Segment secondary style={{ height: 150 }}>
          <Loader active size="medium" content="Loading Sections..." />
        </Segment>
      );
    }
    return (
      <div>
        <SectionsList
          sections={updated_sections}
          page_id={props.id}
          page={page}
          user={user}
          moveSection={moveSection}
          activeIndex={activeIndex}
          updateActiveIndex={updateActiveIndex}
          handleDeleteSection={handleDeleteSection}
        />
      </div>
    );
  }

  if (loading === true) {
    return (
      <div style={{ marginLeft: 180 }}>
        <Dimmer active>
          <Loader size="large" content="Loading Page..." />
        </Dimmer>
      </div>
    );
  }
  if (loading === false) {
    return (
      <div>
        <Grid columns="two" divided>
          <Grid.Row>
            <Grid.Column>
              <div>
                <h3>
                  Page title: {page.title} {"  "} ( User ID: {page.user_id})
                </h3>
                <ToastContainer
                  position="top-center"
                  autoClose={1500}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
                {renderSectionList()}
                <Divider />
                <Segment secondary>
                  <h2>Admin Options</h2>
                  <Divider />
                  {/* <div>{JSON.stringify(page)}</div> */}
                  <div>
                    <p style={{ width: "50%", display: "inline-block" }}>
                      It is important that each section has a sequential index.
                      If not, you can reset the indexing here:
                    </p>
                    <Button
                      primary
                      floated="right"
                      onClick={() => reindexSections()}
                    >
                      Rest Index
                    </Button>
                  </div>
                  <Divider />

                  {page.user_id === user ? (
                    <>
                      {" "}
                      <div>
                        <p style={{ width: "50%", display: "inline-block" }}>
                          This page is assigned to this trust, you can convert
                          it to a common page here:
                        </p>
                        <Button
                          primary
                          floated="right"
                          onClick={() => switchPageToCommon()}
                        >
                          Switch Page to Common
                        </Button>
                      </div>
                      <Divider />
                    </>
                  ) : (
                    <></>
                  )}
                  {page.user_id === "template" ? (
                    <>
                      <div>
                        <p style={{ width: "50%", display: "inline-block" }}>
                          This is a template page, you can copy it to the Trust
                          here:
                        </p>
                        <PageCopy
                          page_id={props.id}
                          page={page}
                          user={user}
                          updated_sections={updated_sections}
                        />
                      </div>
                      <Divider />
                    </>
                  ) : (
                    <></>
                  )}
                  {page.user_id !== "template" ? (
                    <>
                      <div>
                        <p style={{ width: "50%", display: "inline-block" }}>
                          You can copy this page to your templates here:
                        </p>
                        <PageCopyTemplate
                          page_id={props.id}
                          page={page}
                          user={user}
                          updated_sections={updated_sections}
                        />
                      </div>
                      <Divider />
                    </>
                  ) : (
                    <></>
                  )}
                </Segment>
              </div>
            </Grid.Column>

            <Grid.Column>
              <MobileTiles origin={null} sections={updated_sections} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
};

export default Sections;
