import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// import { addSection } from "../../actions";
import {
  Form,
  Button,
  Modal,
  Dropdown,
  Divider,
  Message,
  Radio,
} from "semantic-ui-react";

import { addPage, addSection } from "../../actions";
import _ from "lodash";
import { ToastContainer, toast } from "react-toastify";
const PageCopyTemplate = ({ user, page, updated_sections }) => {
  const dispatch = useDispatch();

  const [modal_open, update_modal_open] = useState(false);
  const [title, update_title] = useState("");
  const [error, update_error] = useState(false);

  function fireToast(type, text) {
    switch (type) {
      case "success":
        toast.success("Submitted - Thank you!", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case "error":
        toast.error("Error - Please remove the link sections", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      case "warning":
        toast.warning(text, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      default:
        break;
    }
  }

  function handleSubmit() {
    const time = new Date().getTime();
    console.log("time");
    console.log(time);
    var error = false;
    var new_page = { ...page };

    new_page.title = title;
    new_page.user_id = "template";
    new_page.page_id = user + ":" + time;

    delete new_page.createdAt;
    delete new_page.updatedAt;
    delete new_page.id;

    var number_of_links = 0;

    updated_sections.map((section) => {
      //Check there are no links
      if (section.type === "link" || section.data === "internal") {
        // error = true;
        number_of_links = number_of_links + 1;
      }
    });
    switch (error) {
      case false:
        dispatch(
          addPage({
            Item: new_page,
          })
        );
        console.log("this is the new Page");
        console.log(new_page);

        updated_sections.map((section) => {
          var new_section = { ...section };
          //new_section.id = section.id + "12asd3";
          new_section.user_id = "template";
          new_section.section_id = new_page.page_id;
          delete new_section.createdAt;
          delete new_section.updatedAt;
          delete new_section.id;
          if (section.type === "link" || section.data === "internal") {
            new_section.destination = "";
          }
          console.log("this is the new section");
          console.log(new_section);
          dispatch(addSection(new_section));
        });
        if (number_of_links > 0) {
          const text =
            "we have removed the destinations from  " +
            Number(number_of_links) +
            "  links";
          fireToast("warning", text);
        }

        fireToast("success");
        update_modal_open(false);
        break;

      case true:
        fireToast("error");
        break;

      default:
        break;
    }
  }

  return (
    <Modal
      trigger={
        <Button primary floated="right" onClick={() => update_modal_open(true)}>
          Copy Page to Templates
        </Button>
      }
      open={modal_open}
      size={"tiny"}
      onClose={() => update_modal_open(false)}
    >
      <Modal.Header>Copy Page Template to Trust</Modal.Header>
      <Modal.Content>
        {error === true ? (
          <Message error header={"this.state.error_message"} />
        ) : (
          <div />
        )}
        <Form>
          <Form.Field>
            <label>
              Page TItle: {title}
              <input
                name="title"
                type="text"
                value={title}
                onChange={(e) => update_title(e.target.value)}
                autoComplete="off"
              />
            </label>
            <Divider />
            <Divider />
            <Button primary onClick={() => handleSubmit()} content="Save" />
            <Button
              secondary
              onClick={() => update_modal_open(false)}
              content="Close"
            />
          </Form.Field>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default PageCopyTemplate;
