import React from "react";
import SectionsSection from "./SectionsSection";
import { Segment } from "semantic-ui-react";
import _ from "lodash";

import SectionNew from "./SectionNew";

const SectionsList = (props) => {
  const ordered_sections = _.sortBy(props.sections, ["index_number"]);
  const sections = props.sections.map((section, index) => {
    console.log(section);
    return (
      <div>
        <SectionsSection
          section={section}
          key={section.id}
          user={props.user}
          moveSection={props.moveSection}
          activeIndex={props.activeIndex}
          updateActiveIndex={props.updateActiveIndex}
          index={index}
          handleDeleteSection={props.handleDeleteSection}
        />
      </div>
    );
  });

  return (
    <div>
      <div>
        <Segment
          style={{ textAlign: "center", background: "#efefef" }}
          color="blue"
        >
          <SectionNew
            page_id={props.page_id}
            page={props.page}
            user={props.user}
          />
        </Segment>
      </div>
      <div>{sections}</div>
    </div>
  );
};

export default SectionsList;
