import shorthash from "shorthash";
import { s3Upload } from "../../../libs/awsLib";
import config from "../../../config";
import s3config from "../../../s3config";
import isUrl from "is-url";
import validator from "validator";

import { Storage } from "aws-amplify";

export function handleChangeText(data) {
  console.log("we are here!!!");
  console.log(data);
  this.setState({ text: data });
}

export function handleChange(event) {
  console.log(event);
  const name = event.target.name;
  console.log({ [name]: event.target.value });
  this.setState({ [name]: event.target.value });
}

export function updateDestination(value) {
  this.setState({ destination: value });
}

export function updateColour(value) {
  this.setState({ colour: value });
}

export function handlePageLinkChange(value) {
  this.setState({ page_link_type: value });
}

export function updateAlternativeLink(value) {
  this.setState({ alternative_link: value });
  console.log("you need coffee");
}

export function updateIcon(value) {
  this.setState({ icon: value });
}

export function handleExtLinkChange(e, { value }) {
  this.setState({ destination: value });
}

export function nullImage(value) {
  console.log("image null");
  this.setState({ image_url: null });
}

export function handleAccordionSelect() {
  if (this.state.type === "text") {
    this.setState({ type: "accordion" });
  }
  if (this.state.type === "accordion") {
    this.setState({ type: "text" });
  }
}
export async function sectionFormChecker() {
  await this.setState({ error_message: "", error: false });
  if (this.state.title.trim() === "") {
    await this.setState({ error_message: "Please enter a name", error: true });
    return;
  }
  switch (this.state.type) {
    case "accordion":
    case "text":
      if (this.state.text.trim() === "") {
        await this.setState({
          error_message: "Please enter text into text area",
          error: true,
        });
      }
      return;
    case "image":
    case "video":
    case "pdf":
      if (this.state.image_url === null) {
        await this.setState({
          error_message: "Please upload an file",
          error: true,
        });
        return;
      }
      return;
    case "link":
      return;
    case "ext_link":
      const check = isUrl(this.state.ext_link);
      if (check === false && this.state.destination === null) {
        await this.setState({
          error_message: "Please enter a valid URL",
          error: true,
        });
      }
      if (this.state.destination === "tel:") {
        const isnum = /^\d+$/.test(this.state.ext_link);
        if (isnum === false) {
          await this.setState({
            error_message: "Please enter a valid number",
            error: true,
          });
        }
      }
      if (this.state.destination === "mailto:") {
        const isEmail = validator.isEmail(this.state.ext_link);
        if (isEmail === false) {
          await this.setState({
            error_message: "Please enter a valid email address",
            error: true,
          });
        }
      }
      return;
    default:
  }
}

export async function upload(event) {
  console.log("running upload function");
  event.preventDefault();
  this.setState({ uploadModalOpen: true });
  this.file = event.target.files[0];
  console.log(this.file);
  var state_object = {};

  if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
    alert(
      `Please pick a file smaller than ${
        config.MAX_ATTACHMENT_SIZE / 1000000
      } MB.`
    );
    return;
  }
  try {
    console.log("trying to upload file");
    const newFile = await s3Upload(this.file);
    console.log(newFile);
    const public_url = s3config.S3.URL + s3config.S3.EXT + newFile;
    const hash = shorthash.unique(public_url);
    state_object = {
      image_url: public_url,
      image_hash: hash,
      uploadModalOpen: false,
      original_image_key: newFile,
    };
    this.setState({ image_url: public_url });
    this.setState({ image_hash: hash });
    this.setState({ original_image_key: newFile });
    this.setState({ uploadModalOpen: false });
    console.log(state_object);
    //this.setState(state_object);
  } catch (e) {
    alert(e);
    console(e);
  }
}

export async function uploadCroppedImage(image, key, image_hash) {
  try {
    const newFile = await s3UploadOverWrite(image, key, image_hash);
    console.log(newFile);
    const public_url = s3config.S3.URL + s3config.S3.EXT + newFile;
    this.setState({
      image_url: public_url,
      cropped_image_key: newFile,
    });
  } catch (e) {
    alert(e);
  }
}

export async function s3UploadOverWrite(file, key, image_hash) {
  let file_name = null;
  if (file_name === null) {
    file_name = `${Date.now()}-${image_hash}`;
    console.log("adding... " + file_name);
  }
  const stored = await Storage.put(file_name, file, {
    progressCallback(progress) {
      const percentage_progress = Math.round(
        (progress.loaded / progress.total) * 100
      );
      const progress_bar = document.getElementById("progress_bar");
      progress_bar.setAttribute("data-percent", percentage_progress);
      progress_bar.childNodes[0].style.width = percentage_progress + "%";
    },
    contentType: file.type,
  });
  return stored.key;
}
