import React, { useState, useEffect } from "react";
import {
  Divider,
  Dropdown,
  Form,
  Radio,
  Grid,
  Segment,
} from "semantic-ui-react";
import colours from "../../../data/colours.json";
import icons from "../../../data/icons.json";
import ImageSection from "../components/ImageSection";

const ExternalLinkSection = ({
  section,
  destination,
  handleExtLinkChange,
  ext_link,
  handleChange,
  updateColour,
  updateIcon,
  button_text,
  upload,
  uploadModalOpen,
  imageUrl,
  nullImage,
  add_image_to_section,
}) => {
  return (
    <div key={destination}>
      <Form>
        <Form.Field>
          <label>
            Button Title:
            <input
              name="button_text"
              type="text"
              value={button_text}
              onChange={(event) => handleChange(event)}
            />
          </label>
          <Divider />
        </Form.Field>
      </Form>

      <Form.Field>
        <Radio
          label="Website URL"
          name="radioGroup"
          value={null}
          checked={destination === null}
          onChange={handleExtLinkChange}
        />
      </Form.Field>
      <Form.Field>
        <Radio
          label="Phone Number"
          name="radioGroup"
          value="tel:"
          checked={destination === "tel:"}
          onChange={handleExtLinkChange}
        />
      </Form.Field>
      <Form.Field>
        <Radio
          label="SMS Link"
          name="radioGroup"
          value="sms:"
          checked={destination === "sms:"}
          onChange={handleExtLinkChange}
        />
      </Form.Field>
      <Form.Field>
        <Radio
          label="Email Link"
          name="radioGroup"
          value="mailto:"
          checked={destination === "mailto:"}
          onChange={handleExtLinkChange}
        />
      </Form.Field>
      <Form.Field>
        <Radio
          key={"Vimeo Embedded"}
          label="Vimeo Embedded"
          name="radioGroup"
          value="vimeo_embed"
          checked={destination == "vimeo_embed"}
          onChange={handleExtLinkChange}
        />
      </Form.Field>
      <Form.Field>
        <Radio
          label="Vimeo Link"
          name="radioGroup"
          value="vimeo"
          checked={destination === "vimeo"}
          onChange={handleExtLinkChange}
        />
      </Form.Field>

      <Divider />
      <Segment secondary>
        <label>
          Enter URL:
          <input
            name="ext_link"
            type="text"
            value={ext_link}
            onChange={handleChange}
          />
        </label>
        {destination === "vimeo_embed" ? (
          <>
            <div>
              <Divider />
              <Segment>
                <Grid columns={2} relaxed="very">
                  <Grid.Column>
                    <h4>Add loading image for video</h4>
                    <ImageSection
                      // timestamp={section.timestamp}
                      section={section}
                      excludeInline={true}
                      includePreview={true}
                      upload={upload}
                      uploadModalOpen={uploadModalOpen}
                      add_image_to_section={add_image_to_section}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <div style={{ padding: 5 }}>
                      <h5>To show generic loading screen, select null</h5>
                      <Form.Field>
                        <Radio
                          label="Set to null"
                          // name="radioGroup"
                          value={null}
                          checked={imageUrl === null}
                          onChange={nullImage}
                        />
                      </Form.Field>
                    </div>
                  </Grid.Column>
                </Grid>
              </Segment>
            </div>
          </>
        ) : (
          <>
            <Divider />
            <label>
              Colour
              <Dropdown
                placeholder="Select Colour"
                fluid
                selection
                search
                defaultValue={section.colour}
                options={colours}
                onChange={(e, { value }) => updateColour(value)}
              />
            </label>
            <Divider />
            <label>
              Icon
              <Dropdown
                placeholder="Select Icon"
                fluid
                selection
                search
                defaultValue={section.icon}
                options={[
                  { key: "None", text: "None", value: "none" },
                  ...icons,
                ]}
                onChange={(e, { value }) => updateIcon(value)}
              />
            </label>
          </>
        )}
      </Segment>
    </div>
  );
};

export default ExternalLinkSection;
