import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  Divider,
  Dropdown,
  Segment,
  Radio,
  Form,
  Button,
} from "semantic-ui-react";
import colours from "../../../data/colours.json";
import icons from "../../../data/icons.json";
import { fetchPathways, fetchJunctionPages } from "../../../actions";

import JunctoinSection from "../../junctionPage/JunctionPageEdit";

const LinkSection = ({
  alternative_link,
  handlePageLinkChange,
  updateAlternativeLink,
  section,
  updateDestination,
  pages,
  updateColour,
  updateIcon,
  page_link_type,
  button_text,
  handleChange,
}) => {
  const dispatch = useDispatch();
  const junctionPages = useSelector((state) => state.junctionPages);
  const user = useSelector((state) => state.user);

  const [JunctionPage, update_JunctionPage] = useState(
    alternative_link || null
  );
  const [loading, update_loading] = useState(true);

  const [junction_data_to_parse, update_junction_data_to_parse] =
    useState(null);

  useEffect(() => {
    if (loading === true) {
      console.log(junctionPages);
      dispatch(fetchPathways(user["custom:trust"]));
      dispatch(fetchJunctionPages(user["custom:trust"]));
      update_junction_page(JunctionPage);
      update_loading(false);
    }
  }, []);

  function update_junction_page(value) {
    updateAlternativeLink(value);
    update_JunctionPage(value);
    update_junctions(value);
  }

  function update_junctions(value) {
    var junction_data_to_parse_to_state = null;
    if (_.find(junctionPages, { id: value }) !== undefined) {
      junction_data_to_parse_to_state = _.find(junctionPages, {
        id: value,
      });
      update_junction_data_to_parse(junction_data_to_parse_to_state);
    }
  }

  const junctionSelection = () => {
    return (
      <div>
        <h4 style={{ marginTop: 20 }}>Select junction pages</h4>
        <Dropdown
          placeholder="Select Junction Page"
          fluid
          selection
          search
          defaultValue={JunctionPage}
          selectedLabel={JunctionPage}
          options={junctionPages.map((junction_page) => ({
            key: junction_page.id,
            value: junction_page.id,
            text: junction_page.title,
          }))}
          onChange={(e, { value }) => update_junction_page(value)}
        />
        {junction_data_to_parse ? (
          <JunctoinSection
            id={junction_data_to_parse.id}
            junction_page={junction_data_to_parse}
            user={user}
          />
        ) : (
          <></>
        )}
      </div>
    );
  };

  return (
    <div>
      {/* {section.page_link_type} */}
      <Form>
        <Form.Field>
          <label>
            Button Title:
            <input
              name="button_text"
              type="text"
              value={button_text}
              onChange={(event) => handleChange(event)}
            />
          </label>
          <Divider />
        </Form.Field>
      </Form>
      <Segment>
        <h4>Select the type of page link you want:</h4>
        <Form.Field>
          <Radio
            label="Normal Page Link"
            name="radioGroup"
            value="normal"
            checked={page_link_type === "Normal"}
            onChange={() => handlePageLinkChange("Normal")}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label="Junction Page"
            name="radioGroup"
            value="junction"
            checked={page_link_type === "Junction"}
            onChange={() => handlePageLinkChange("Junction")}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label="Wellness Tracker"
            name="radioGroup"
            value="Wellness"
            checked={page_link_type === "Wellness"}
            onChange={() => handlePageLinkChange("Wellness")}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label="Timeline"
            name="radioGroup"
            value="Timeline"
            checked={page_link_type === "Timeline"}
            onChange={() => handlePageLinkChange("Timeline")}
          />
        </Form.Field>
      </Segment>
      <Segment secondary>
        {page_link_type === "Junction" || page_link_type === "Normal" ? (
          <>
            <label>
              Destination
              <Dropdown
                placeholder="Select Page"
                fluid
                selection
                search
                handleChangePage
                defaultValue={section.destination}
                onChange={(e, { value }) => updateDestination(value)}
                selectedLabel={pages.destination}
                options={pages.map((page) => ({
                  key: page.page_id,
                  value: page.page_id,
                  text: page.title,
                }))}
              />
            </label>
            <Divider />
          </>
        ) : (
          <></>
        )}

        <label>
          Colour
          <Dropdown
            placeholder="Select Colour"
            fluid
            selection
            search
            defaultValue={section.colour}
            options={colours}
            onChange={(e, { value }) => updateColour(value)}
          />
          <Divider />
        </label>
        <label>
          Icon
          <Dropdown
            placeholder="Select Icon"
            fluid
            selection
            search
            defaultValue={section.icon}
            options={[{ key: "None", text: "None", value: "none" }, ...icons]}
            onChange={(e, { value }) => updateIcon(value)}
          />
        </label>
      </Segment>
      {page_link_type === "Junction" ? (
        <>
          <Segment>
            <div>Junction pages</div>
            {junctionSelection()}
          </Segment>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default LinkSection;
