import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// import { addSection } from "../../actions";
import {
  Form,
  Button,
  Modal,
  Dropdown,
  Divider,
  Message,
  Radio,
} from "semantic-ui-react";

import { addPage, addSection } from "../../actions";
import _ from "lodash";

const PageCopy = ({ user, page, updated_sections }) => {
  const dispatch = useDispatch();

  const [modal_open, update_modal_open] = useState(false);
  const [title, update_title] = useState("");
  const [error, update_error] = useState(false);

  function handleSubmit() {
    const time = new Date().getTime();
    console.log("time");
    console.log(time);
    var error = false;
    var new_page = { ...page };

    new_page.title = title;
    new_page.user_id = user;
    new_page.page_id = user + ":" + time;

    delete new_page.createdAt;
    delete new_page.updatedAt;
    delete new_page.id;

    updated_sections.map((section) => {
      //Check there are no links
      if (section.type === "link") {
        //error = true;
      }
    });
    switch (error) {
      case false:
        dispatch(
          addPage({
            Item: new_page,
          })
        );

        updated_sections.map((section) => {
          var new_section = { ...section };
          //new_section.id = section.id + "12asd3";
          new_section.user_id = user;
          new_section.section_id = new_page.page_id;
          delete new_section.createdAt;
          delete new_section.updatedAt;
          delete new_section.id;

          dispatch(
            addSection({
              Item: new_section,
            })
          );
        });
        update_modal_open(false);
        // fireToast("success");
        break;

      case true:
        // fireToast("error");
        break;

      default:
        break;
    }
  }

  return (
    <Modal
      trigger={
        <Button primary floated="right" onClick={() => update_modal_open(true)}>
          Copy Template to Trust
        </Button>
      }
      open={modal_open}
      size={"tiny"}
      onClose={() => update_modal_open(false)}
    >
      <Modal.Header>Copy Page Template to Trust</Modal.Header>
      <Modal.Content>
        {error === true ? (
          <Message error header={"this.state.error_message"} />
        ) : (
          <div />
        )}
        <Form>
          <Form.Field>
            <label>
              Page TItle: {title}
              <input
                name="title"
                type="text"
                value={title}
                onChange={(e) => update_title(e.target.value)}
                autoComplete="off"
              />
            </label>
            <Divider />
            <Divider />
            <Button primary onClick={() => handleSubmit()} content="Save" />
            <Button
              secondary
              onClick={() => update_modal_open(false)}
              content="Close"
            />
          </Form.Field>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default PageCopy;
