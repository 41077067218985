import React, { useState, useEffect } from 'react';
import { Location } from '@reach/router'

import Page from '../../components/sections/Page'
import Layout from "../../components/layout"
import queryString from 'query-string'

const Index = () => {


    return (
        <Layout>
            <div>
                <Location>
                    {({ location, navigate }) => (
                    <Page
                        location={location}
                        navigate={navigate}
                        search={location.search ? queryString.parse(location.search) : {}}
                    />
                    
                    )}
                </Location>
            </div>
        </Layout>
    )
}

export default Index
