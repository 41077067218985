import React from "react";
import { Form, Button, Modal, Grid } from "semantic-ui-react";

class SectionOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index_number: this.props.section.index_number,
      modalOpen: false,
    };
    // this.handleChange = this.handleChange.bind(this);
    //this.handleDeleteSection = this.props.handleDeleteSection.bind(this);
  }

  handleOpen = () => this.setState({ modalOpen: true });
  handleClose = () => this.setState({ modalOpen: false });

  // handleChange(event) {
  //   const index_int = Number(event.target.value)
  //   this.setState({index_number: index_int});
  //   this.props.modalUpdateIndex(event, index_int)
  // }

  handleDelete = (event) => {
    console.log("hello");
    //this.props.handleDelete();
    this.props.handleDeleteSection();
  };

  handleSave = (event) => {
    console.log("trying to save");
    this.props.handleSubmit(event);
    this.setState({ modalOpen: false });
  };

  render() {
    return (
      <Modal
        trigger={
          <Button basic onClick={this.handleOpen}>
            Options
          </Button>
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
      >
        <Modal.Header>Section Options</Modal.Header>
        <Modal.Content>
          <Grid columns={2} relaxed="very">
            <Grid.Column>
              <h4>Update Options</h4>
              <Button secondary onClick={this.handleClose} content="Close" />
              <Button primary onClick={this.handleSave} content="Save" />
              <Button
                negative
                onClick={this.handleDelete}
                content="Delete Section"
              />
            </Grid.Column>
            <Grid.Column>
              <div style={{ textAlign: "right" }}>
                <Form>
                  <Form.Field>
                    <h4>Manually Update Section Index:</h4>
                    <label>
                      {/* <input
                        style={{width: 212}}
                        name="text"
                        type="number"
                        value={this.state.index_number}
                        onChange={this.handleChange}
                      /> */}
                    </label>
                  </Form.Field>
                </Form>
              </div>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}
export default SectionOptions;
